import axios from "axios";

import type { Entity } from "../../models/Entity";
import { EntityTypes } from "../../models/Entity";

import type { APIEntity } from "./typings";
import { convertResponseToEntity } from "./utils/convertResponseToEntity";

export async function getEntities(
  userId: string,
  productId: string
): Promise<Entity[]> {
  const notesPromise = axios.get<ResponseJSON>(
    `${process.env.USERDATA_API}/api/Notes?userID=${encodeURIComponent(
      userId
    )}&productID=${encodeURIComponent(productId)}`
  );
  const templatesPromise = axios.get<ResponseJSON>(
    `${process.env.USERDATA_API}/api/NoteTemplates?userID=${encodeURIComponent(
      userId
    )}`
  );

  const [notes, templates] = await Promise.all([
    notesPromise,
    templatesPromise,
  ]);

  return [
    ...(notes.data ?? []).map((entity) =>
      convertResponseToEntity(entity, EntityTypes.NOTE)
    ),
    ...(templates.data ?? []).map((entity) =>
      convertResponseToEntity(entity, EntityTypes.TEMPLATE)
    ),
  ];
}

type ResponseJSON = APIEntity[] | undefined;
