import axios from "axios";

import type { Note } from "../../models/Entity";
import { EntityTypes } from "../../models/Entity";

import { convertResponseToEntity } from "./utils/convertResponseToEntity";

export async function getPredefinedNote(objectId: string): Promise<Note> {
  const result = await axios.get<Response>(
    `${process.env.COMPONENT_FRAMEWORK_API}/api/Components/Get/${objectId}`
  );
  const content = convertResponseToEntity(
    JSON.parse(result.data.Content),
    EntityTypes.NOTE
  ) as Note & {
    lockedContents: string;
    contents: string;
  };

  const note: Note = {
    ...content,
    content: content.contents,
    lockedContent: content.lockedContents,
    predefinedNoteID: objectId,
    productId: window.gNotebook.productId,
    shared: false,
    title: result.data.Title,
    type: "fromPredefined",
    userID: window.gProxy.getToken().UserId ?? "",
    userInstitutionId: window.gNotebook.institutionId,
  };

  return note;
}

type Response = {
  Title: string;
  Content: string;
};
