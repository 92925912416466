import axios from "axios";

import type { Entity } from "../../models/Entity";
import { EntityTypes } from "../../models/Entity";

import { convertEntityToRequest } from "./utils/convertEntityToRequest";

type ObjectID = string;

export async function createEntity(entity: Entity): Promise<ObjectID> {
  const url =
    entity.tab === EntityTypes.NOTE
      ? `${process.env.USERDATA_API}/api/Notes`
      : `${process.env.USERDATA_API}/api/NoteTemplates`;

  const response = await axios.request<{ objectID: ObjectID }>({
    url,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(convertEntityToRequest(entity)),
  });

  return response.data.objectID;
}
