import { createEntity } from "./api/entities/createEntity";
import { deleteEntity } from "./api/entities/deleteEntity";
import { getEntities } from "./api/entities/getEntities";
import { getPredefinedNote } from "./api/entities/getPredefinedNote";
import { updateEntity } from "./api/entities/updateEntity";
import type { Context } from "./models/Context";
import type { Entity, Note, Template } from "./models/Entity";
import { EntityTypes } from "./models/Entity";
import { CustomError } from "./utils/CustomError";
import { IsMobile } from "./utils/IsMobile";
import { asyncTimeout } from "./utils/asyncTimeout";

const cache = Symbol("cache");
const isLoaded = Symbol("isLoaded");
const isOpen = Symbol("isOpen");
const loadPromise = Symbol("loadPromise");
const events = Symbol("events");
const frame = Symbol("frame");
const currentWidth = Symbol("currentWidth");
const isExpanded = Symbol("isExpanded");
const dragBar = Symbol("dragBar");
const throbber = Symbol("throbber");
const isCurrentlyUnmounting = Symbol("isCurrentlyUnmounting");
const destroyed = Symbol("destroyed");
const currentContext = Symbol("currentContext");

/** Specifies the paramaters related to instantiating a notebook */
type NotebookParams = {
  /**
   * If given, the notebook will automatically navigate to this specific note
   * when loaded.
   */
  noteId?: string;

  /**
   * If given, the notebook will automatically navigate to this specific
   * template when loaded.
   */
  templateId?: string;

  /**
   * Specifies whether or not the notebook should contain the expand-button
   * when rendered.
   */
  canExpand?: boolean;

  /** Add the following CSS-class to iframe. */
  className?: string;

  /** An object wrapping the context parameters. */
  context?: Context;
};

export type ErrorMessages =
  | "RequestOpenNotBoundError"
  | "RequestCloseNotBoundError"
  | "ChangesNotSavedError"
  | "UnmountAbortedError"
  | "NotebookUnmountedError";

/**
 * Class responsible for taking care of the gNoteBook
 */
class gNotebook {
  /** Specifies the id of the current instance */
  public static instanceId = 0;

  /** Contains an object of all currently running instances of the notebook */
  public static instances: Record<string, gNotebook> = {};

  /** Specifies whether or not it is allowed to embed the notebook */
  public static allowEmbed = true;

  /** Specifies the id of the product */
  public static productId: string;

  /** Specifies the title of the product */
  public static productTitle: string;

  /** Specifies if Geogebra should be disabled when opening the notebook */
  public static disableGeogebra = false;

  /**
   * Specifies the ID of the institution that notes created in this session
   * should be associated with.
   */
  public static institutionId: string;

  /** Specifies a string of the provider to be used */
  public static provider: string;

  private static [isOpen]: boolean;
  private static [cache]: Record<string, unknown>;
  public static [currentContext]: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static [events]: Record<string, any>;

  /**
   * This namespace will contain a map of exceptions thrown by the gNotebook-
   * class.
   */
  public static ERROR_MESSAGES: Record<string, string> = {
    /**
     * This error is thrown if the "requestOpen"-method is triggered and
     * no methods have actually been bound to the corresponding event.
     *
     */
    RequestOpenNotBoundError:
      'No handlers have been bound to the "request:open"-event.',

    /**
     * This error is thrown if the "requestClose"-method is triggered and
     * no methods have actually been bound to the corresponding event.
     */
    RequestCloseNotBoundError:
      'No handlers have been bound to the "request:close"-event.',

    /**
     * This error is thrown if the notebook was unable to save the user's
     * changes before unmounting itself.
     */
    ChangesNotSavedError: "The user's changes could not be auto-saved.",

    /**
     * This error is thrown if the client called {@link gNotebook#abortUnmount}
     * before the notebook could be destroyed.
     */
    UnmountAbortedError:
      "The attempt to unmount the notebook was aborted by the client.",

    /**
     * This error is thrown if the client attempts to call {@link
     * gNotebook#abortUnmount} after the notebook has already been completely
     * destroyed.
     */
    NotebookUnmountedError: "The notebook has already been unmounted.",
  };

  /** Will contain the custom errors defined above once the file is loaded */
  public static ERRORS: Record<string, typeof CustomError>;

  /** Specifies the id of the current instance */
  private instanceId: number;

  /** Specifies the productId of the current instance */
  private productId: string;

  /** Specifies the id of the current user */
  private userId: string | undefined;

  private [events]: Record<string, unknown>;
  private [loadPromise]: Promise<unknown> & {
    resolve: () => void;
    reject: () => void;
  };
  private [frame]: HTMLIFrameElement;
  private [throbber]?: HTMLElement;
  private [isLoaded] = false;
  private [dragBar]?: HTMLDivElement;
  private [isExpanded] = false;
  private [currentWidth] = 0;
  private [isCurrentlyUnmounting] = false;
  private [destroyed] = false;

  /**
   * External utility class for mounting the notebook in external products,
   * managing state and creating / modifying notes.
   *
   * @see {@tutorial gNotebook}
   */
  constructor(params: NotebookParams = {}) {
    this.userId = window.gProxy.getToken().UserId;
    this.productId = window.gNotebook.productId;

    this.instanceId = ++gNotebook.instanceId;
    gNotebook.instances[this.instanceId] = this;

    this[events] = {};

    // Create a promise that will be resolved when the frame is loaded
    // (see static onMessage for how it is resolved)
    let tmpResolve: () => void;
    let tmpReject: () => void;

    // @ts-expect-error Legacy symbol based issuess
    this[loadPromise] = new Promise((resolve, reject) => {
      // @ts-expect-error Legacy symbol based issuess
      tmpResolve = resolve;
      tmpReject = reject;
    });

    setTimeout(() => {
      this[loadPromise].resolve = tmpResolve;
      this[loadPromise].reject = tmpReject;
    });

    // Make sure that no errors are thrown if the load promise is rejected
    // (ie. the notebook is destroyed before load finishes)
    this[loadPromise].then(
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {},
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {}
    );

    // Create the iframe, and mount it into the
    this[frame] = gNotebook.createFrame();
    this[frame].className = params.className ?? "";

    // Once the notebook-client has loaded, we no longer need to have a
    // solid background-color (in fact this would break the expand/collapse
    // transitions)
    this.wait(() => {
      setTimeout(() => {
        if (!this[throbber]) {
          return;
        }

        if (this[throbber]) {
          this[throbber]?.remove();
        }

        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete this[throbber];
      }, 300);
    });

    // Make sure that context is set correctly upon opening of the notebook
    const context = params.context ?? window.gNotebook[currentContext];

    // Wait 400ms before loading the notebook, so the thirdparty application
    // has a chance to animate it into view with out lagging
    setTimeout(() => {
      let url =
        (process.env["GNOTEBOOK_URL"] ?? "") +
        `${params.noteId ? `${EntityTypes.NOTE}/${params.noteId}/` : ""}` +
        `${
          params.templateId ? `${EntityTypes.TEMPLATE}/${params.noteId}/` : ""
        }` +
        `?userID=${encodeURIComponent(window.gProxy.getToken().UserId ?? "")}` +
        `&institutionID=${encodeURIComponent(gNotebook.institutionId)}` +
        `&productID=${encodeURIComponent(gNotebook.productId)}` +
        `&canExpand=${
          params.canExpand === true || params.canExpand === false
            ? params.canExpand
            : window.innerWidth >= 768
        }` +
        `&hideHeader=true` +
        `&allowBacklink=${
          window.gNotebook[events] &&
          window.gNotebook[events].backlink &&
          window.gNotebook[events].backlink.length
            ? "true"
            : "false"
        }` +
        `&instanceId=${this.instanceId}`;

      if (gNotebook.disableGeogebra) {
        url += "&disableGeogebra";
      }

      if (context && context.page) {
        url += `&context=${encodeURIComponent(`page-${context.page.id}`)}`;
      } else if (context && context.learningObject) {
        url += `&context=${encodeURIComponent(
          `object-${context.learningObject.id}`
        )}`;
      }

      if (context) {
        this.setContext(context);
      }

      url = window.gProxy.forwardTo(url, "notebook-3.0");

      this[frame].src = url;
    }, 400);

    // Inject the notebook into the DOM
    document.body.appendChild(this[frame]);
  }

  /**
   * Use this method to update the ID of the currently open product, which
   * the notebook should load notes from.
   */
  public static setProductId(productId: string): void {
    this.productId = productId;
  }

  /**
   * Use this method to supply the ID of the institution that the current
   * user session is associated with (for retention policy compliance).
   */
  public static setInstitutionId(institutionId: string): void {
    this.institutionId = institutionId;
  }

  /**
   * Use this method to update the data of the currently open product, which
   * the notebook should load notes from.
   */
  public static setProduct({ id, title }: { id: string; title: string }): void {
    this.productId = id;
    this.productTitle = title;
  }

  /**
   * Use this method to specific an external notebook provider that should be
   * used inside this product. Currently supports only "onenote".
   */
  public static setProvider(provider: string): void {
    this.provider = provider;
  }

  /**
   * Use this method to specify whether or not to allow the notebook to be
   * embedded on this page (or to always open the note in a new window).
   */
  public static setAllowEmbed(allow: boolean): void {
    this.allowEmbed = allow;
  }

  /**
   * Use this method to toggle geogebra on/off when opening the notebook in a
   * new window.
   */
  public static toggleGeogebra(enabled: boolean): void {
    this.disableGeogebra = !enabled;
  }

  /**
   * Helper-method that specifies the context that is currently open in the
   * thirdparty product. Using this method makes sure that notes are associa-
   * ted with the currently active context, when the user creates a new note.
   */
  public static setContext(context: Context): void {
    // Propagate the call to all currently active clients
    Object.keys(this.instances).forEach((instanceId) => {
      if (
        !this.instances[instanceId] ||
        !this.instances[instanceId].setContext
      ) {
        return;
      }

      this.instances[instanceId].setContext(context);
    });

    // Update currently specified context
    this[currentContext] = context;
  }

  /**
   * This method triggers the "request:open"-method with the given parameters
   * being forwarded directly to any bound event-listeners.
   */
  public static requestOpen(params: NotebookParams = {}): void {
    if (this.provider === "onenote") {
      if (typeof window.gExport === "undefined") {
        throw new Error(
          "gNotebook.requestOpen(): Unable to use OneNote provider, gExport library not loaded!"
        );
      }

      if (!params.noteId) {
        window.gExport.OneNoteApi.openNotebook({
          productId: this.productId,
          productTitle: this.productTitle,
        }).catch((err) => {
          alert(
            "Der opstod en fejl da vi forsøgte at åbne din OneNote notesbog, vi beklager."
          );

          throw err;
        });

        return;
      }

      window.gExport.OneNoteApi.openNote({
        backlink: window.location.href,
        noteId: params.noteId,
        productId: this.productId,
        productTitle: this.productTitle,
      }).catch((err) => {
        alert(
          "Der opstod en fejl da vi forsøgte at åbne din OneNote notesbog, vi beklager."
        );

        throw err;
      });

      return;
    }

    const context: Context | undefined = params.context || this[currentContext];

    if (!gNotebook.allowEmbed) {
      // Generate base URL
      let url =
        (process.env["GNOTEBOOK_URL"] ?? "") +
        `${params.noteId ? `${EntityTypes.NOTE}/${params.noteId}/` : ""}` +
        `${
          params.templateId ? `${EntityTypes.TEMPLATE}/${params.noteId}/` : ""
        }` +
        `?userID=${encodeURIComponent(window.gProxy.getToken().UserId ?? "")}` +
        `&productID=${encodeURIComponent(gNotebook.productId)}`;

      // Append context to URL
      if (context && context.learningObject) {
        url += `&context-learningObjectId=${encodeURIComponent(
          context.learningObject.id
        )}`;
        url += `&context-learningObjectTitle=${encodeURIComponent(
          context.learningObject.title
        )}`;
        url += `&context-learningObjectType=${encodeURIComponent(
          context.learningObject.type
        )}`;
      } else if (context && context.page) {
        url += `&context-pageId=${encodeURIComponent(context.page.id)}`;
        url += `&context-pageTitle=${encodeURIComponent(context.page.title)}`;
      }

      // Forward through the node.js proxy to ensure authentication
      url = window.gProxy.forwardTo(url, "notebook-3.0");

      // Open in a new window that's centered around the current window
      const windowElm = $(window);
      const windowHeight = windowElm.height();
      const windowWidth = windowElm.width();

      // Bail out if either width or height isn't available
      if (!windowHeight || !windowWidth) {
        return;
      }

      const openedElm = window.open(
        url,
        "notebook",
        `left=${window.screenLeft + 50},` +
          `top=${window.screenTop + 50},` +
          `width=${windowWidth - 100},` +
          `height=${windowHeight - 100}`
      );

      if (openedElm) {
        openedElm.focus();
      }

      return;
    }

    if (!this[events]["request:open"] || !this[events]["request:open"].length) {
      throw new gNotebook.ERRORS.RequestOpenNotBoundError();
    }

    this.fire("request:open", params);
    this[isOpen] = true;
  }

  /**
   * This method triggers the "request:close"-method.
   */
  public static requestClose(): void {
    if (
      !this[events]["request:close"] ||
      !this[events]["request:close"].length
    ) {
      throw new gNotebook.ERRORS.RequestCloseNotBoundError();
    }

    this.fire("request:close");
    this[isOpen] = false;
  }

  /**
   * Helper-method that returns current state of the notebook-implementation.
   */
  public static isOpen(): boolean {
    return this[isOpen] === true;
  }

  /**
   * Internal helper-method that allows us to fire events on the model. Sub-
   * scribing to events is done using the on/once/off-methods.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static fire(event: string, ...args: any[]): void {
    if (!this[events][event]) {
      return;
    }

    this[events][event].forEach((listener: (...args: unknown[]) => void) => {
      listener(...args);
    });
  }

  /** Helper-method that allows us to bind to internal events of the model. */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static on(event: string, listener: (...args: any[]) => void): void {
    if (!this[events][event]) {
      this[events][event] = [];
    }

    this[events][event].push(listener);
  }

  /**
   * Helper-method that allows us to bind to the first upcoming internal
   * event and then automatically unbind the listener again.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static once(event: string, listener: (...args: any[]) => void): void {
    // Create a handler that will trigger the listener and then unbind
    // itself after first execution
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handler = (...args: any[]) => {
      listener(...args);
      this.off(event, handler);
    };

    this.on(event, handler);
  }

  /**
   * Helper-method that unbinds a listener from a given event. In order to
   * remove all events at once, simply pass a wildcard as the event-parameter
   * and leave listener blank (eg. `model.off('*');`).
   */
  public static off(
    event: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    listener?: ((...args: any[]) => void) | string
  ): void {
    if (!this[events][event]) {
      return;
    }

    // When given a wildcard for event, delete for all events
    if (event === "*") {
      // If listener is a wildcard as well, then remove all events at
      // once
      if (!listener || listener === "*") {
        this[events] = {};

        return;
      }

      // ... Otherwise propagate to all events
      Object.keys(this[events]).forEach((eventName) => {
        this.off(eventName, listener);
      });

      return;
    }

    // When given a wildcard for listener, delete all listeners for this
    // event
    if (listener === "*") {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete this[events][event];

      return;
    }

    // Filter specific event-handler
    this[events][event] = this[events][event].filter((handler: () => void) => {
      return handler !== listener;
    });
  }

  /**
   * Listens for messages posted from the notebook instance, allowing us to
   * communicate directly with the notebook. This cross-communication allows
   * us to listen for events from inside the notebook (for instance expand
   * and collapse requests).
   */
  public static onMessage(evt: MessageEvent): void {
    // Messages are expected to be strings for cross browser support
    if (typeof evt.data !== "string") {
      return;
    }

    // If the origin of the event doesn't match our notebook, then bail out!
    if (process.env["GNOTEBOOK_URL"]?.indexOf(evt.origin) !== 0) {
      return;
    }

    // Parse message format
    const tmp = evt.data.split("/");
    const event = tmp[0];
    const instance = gNotebook.instances[tmp[1]];
    const data = tmp.slice(2).join("/");

    if (!instance || !(instance instanceof gNotebook)) {
      return;
    }

    let parsedData: {
      userId: string;
      productId: string;
      notes: Array<Entity & { tags: string[] }>;
    };

    switch (event) {
      case "notebook:ready":
        instance[isLoaded] = true;
        instance[loadPromise].resolve();
        break;

      case "notebook:expand":
        instance.onExpand();
        instance.fire("expand");
        break;

      case "notebook:before:collapse":
        instance.onBeforeCollapse();
        break;

      case "notebook:collapse":
        instance.fire("collapse");
        instance.onCollapse();
        break;

      case "notebook:save:fail":
        instance.fire(`save:fail:${data}`);
        break;

      case "notebook:save:done":
        instance.fire(`save:done:${data}`);
        break;

      case "notebook:update:notes":
        parsedData = JSON.parse(data);

        window.gNotebook.cacheNotes({
          notes: parsedData.notes as Note[],
          productId: parsedData.productId,
          userId: parsedData.userId,
        });
        break;

      case "notebook:update:templates":
        parsedData = JSON.parse(data);

        window.gNotebook.cacheTemplates({
          templates: parsedData.notes as Template[],
          userId: parsedData.userId,
        });
        break;

      case "notebook:backlink":
        window.gNotebook.fire("backlink", JSON.parse(data));
        break;

      default:
    }
  }

  /** Load notes for the user currently logged into the node.js proxy. */
  public static async loadNotes(): Promise<Note[]> {
    if (!("productId" in window.gNotebook)) {
      throw new Error("Product-id has not been defined.");
    }

    const userId = window.gProxy.getToken().UserId ?? "";
    const productId = window.gNotebook.productId;

    try {
      // If data has not been loaded yet, then do it now
      if (!this.getNotesFromCache()) {
        this.cacheNotes({
          notes: (await getEntities(userId, productId)).filter(
            (entity) => entity.tab === EntityTypes.NOTE
          ) as Note[],
          productId,
          userId,
        });
      }

      return this.getNotesFromCache();
    } catch (err) {
      console.error("gNotebook.loadNotes(): An error occurred.");
      throw err;
    }
  }

  /** Internal helper-methods to handle local cache */
  public static cacheNotes({
    userId,
    productId,
    notes,
  }: {
    userId: string;
    productId: string;
    notes: Note[];
  }): void {
    this[cache][`notes-${userId}-${productId}`] = notes;
    this.fire("update:notes");
  }

  /** Helper that returns all notes from cache */
  public static getNotesFromCache(
    params: { userId?: string; productId?: string } = {}
  ): Note[] {
    const userId = params.userId ?? window.gProxy.getToken().UserId;
    const productId = params.productId || window.gNotebook.productId;

    return this[cache][`notes-${userId}-${productId}`] as Note[];
  }

  /**
   * Helper-method that creates a new note for the user, based on the given
   * predefined note.
   */
  public static async addNoteFromPredefined({
    predefinedNoteId,
  }: {
    predefinedNoteId: string;
  }): Promise<string> {
    try {
      const userId = window.gProxy.getToken().UserId ?? "";
      const productId = window.gNotebook.productId;

      // Has the user already created a predefined note?
      const allNotes = await this.loadNotes();

      let note:
        | (Note & { productID?: string; userInstitutionID?: string })
        | undefined = allNotes.find((tmp) => {
        return tmp.predefinedNoteID === predefinedNoteId;
      });

      if (!note) {
        // Load data about the predefined note!
        const predefinedNote = {
          ...(await getPredefinedNote(predefinedNoteId)),
          productId,
          userInstitutionId: this.institutionId,
          userID: userId,
        };

        if (window.gNotebook[currentContext]) {
          predefinedNote.context = window.gNotebook[currentContext];
        }

        // Create a new note on behalf of the user
        const noteId = await createEntity(predefinedNote);

        note = {
          ...predefinedNote,
          tab: EntityTypes.NOTE,
          created: Math.round(Date.now() / 1000),
          modified: Math.round(Date.now() / 1000),
          objectID: noteId,
        };

        // Now insert the note in the internal cache
        const notes = this.getNotesFromCache({ userId, productId });

        notes.push(note as Note);
        this.cacheNotes({ userId, productId, notes });

        // Push update to instances of the notebook
        this.pushNotesToInstances({ userId, productId });
      }

      if (!note || !note.objectID) {
        throw new Error("Unknown error");
      }

      // Wait a millisecond before resolving, so we are sure that the
      // data-update has propagated to any open client
      await asyncTimeout(1);

      // Return the ID of the newly created note
      return note.objectID;
    } catch (err) {
      if (err instanceof Error) {
        throw new Error(`Remote API failed. Exception message: ${err.message}`);
      } else {
        console.error("Remote API failed");
        throw err;
      }
    }
  }

  /** Creates a note with the specified data. */
  public static async createNote(data: Note): Promise<string> {
    // Make sure that the list of all notes have been cached before
    // creating this note
    if (!this.getNotesFromCache()) {
      await this.loadNotes();
    }

    const userId = window.gProxy.getToken().UserId ?? "";
    const productId = window.gNotebook.productId;

    const note = {
      ...data,
      tab: EntityTypes.NOTE,
      userID: userId,
      userInstitutionId: this.institutionId,
      productId,
    };

    // Create note in the remote API
    const objectID = await createEntity(note);

    // Now insert the note in the internal cache
    const notes = this.getNotesFromCache();

    notes.push({
      ...note,
      created: Math.round(Date.now() / 1000),
      modified: Math.round(Date.now() / 1000),
      objectID,
    } as Note);

    this.cacheNotes({ userId, productId, notes });

    // Push update to instances of the notebook
    this.pushNotesToInstances({ userId, productId });

    // Wait a millisecond before resolving, so we are sure that the
    // data-update has propagated to any open client
    await asyncTimeout(1);

    // Resolve promise
    return objectID;
  }

  /** Updates a note with the specified data. */
  public static async updateNote(data: Note): Promise<string | undefined> {
    // Make sure that the list of all notes have been cached before
    // updating this note
    if (!this.getNotesFromCache()) {
      await this.loadNotes();
    }

    const userId = window.gProxy.getToken().UserId ?? "";
    const productId = window.gNotebook.productId;

    // Update note in the remote API
    await updateEntity(data);

    // Now update the note in the internal cache
    const notes = this.getNotesFromCache().map((note) => {
      if (note.objectID !== data.objectID) {
        return note;
      } else {
        return { ...note, ...data, modified: Math.round(Date.now() / 1000) };
      }
    });

    this.cacheNotes({ userId, productId, notes });

    // Push update to instances of the notebook
    this.pushNotesToInstances({ userId, productId });

    // Wait a millisecond before resolving, so we are sure that the
    // data-update has propagated to any open client
    await asyncTimeout(1);

    // Resolve promise
    return data.objectID;
  }

  /**
   * Deletes the given note.
   */
  public static async deleteNote(data: { objectID: string }): Promise<null> {
    // Make sure that the list of all notes have been cached before
    // deleting this note
    if (!this.getNotesFromCache()) {
      await this.loadNotes();
    }

    const userId = window.gProxy.getToken().UserId ?? "";
    const productId = window.gNotebook.productId;

    // Delete note from the remote API
    await deleteEntity(EntityTypes.NOTE, data.objectID);

    // Now remove the note from the internal cache
    const notes = this.getNotesFromCache().filter((note) => {
      return note.objectID !== data.objectID;
    });

    this.cacheNotes({ userId, productId, notes });

    // Push update to instances of the notebook
    this.pushNotesToInstances({ userId, productId });

    // Wait a millisecond before resolving, so we are sure that the
    // data-update has propagated to any open client
    await asyncTimeout(1);

    // Resolve promise
    return null;
  }

  /** Load templates for the user currently logged into the node.js proxy. */
  public static async loadTemplates(): Promise<Template[]> {
    if (!("productId" in window.gNotebook)) {
      throw new Error("Product-id has not been defined.");
    }

    const userId = window.gProxy.getToken().UserId ?? "";
    const productId = window.gNotebook.productId;

    try {
      // If data has not been loaded yet, then do it now
      if (!this.getTemplatesFromCache()) {
        this.cacheTemplates({
          templates: (await getEntities(userId, productId)).filter(
            (entity) => entity.tab === EntityTypes.TEMPLATE
          ) as Template[],
          userId,
        });
      }

      return this.getTemplatesFromCache();
    } catch (err) {
      console.error("gNotebook.loadTemplates(): An error occurred.");
      throw err;
    }
  }

  /** Internal helper-methods to handle local cache */
  public static cacheTemplates(params: {
    userId: string;
    templates: Template[];
  }): void {
    this[cache][`templates-${params.userId}`] = params.templates;
    this.fire("update:templates");
  }

  /** Helper that returns all templates from local cache */
  public static getTemplatesFromCache(
    params: { userId?: string } = {}
  ): Template[] {
    const userId = params.userId ?? window.gProxy.getToken().UserId;

    return this[cache][`templates-${userId}`] as Template[];
  }

  /** Creates a template with the specified data. */
  public static async createTemplate(data: Template): Promise<string> {
    // Make sure that the list of all templates have been cached before
    // creating this template
    if (!this.getTemplatesFromCache()) {
      await this.loadTemplates();
    }

    const userId = window.gProxy.getToken().UserId ?? "";

    const template = {
      ...data,
      tab: EntityTypes.TEMPLATE,
      userID: userId,
    };

    // Create template in the remote API
    const templateId = await createEntity(template);

    // Now insert the template in the internal cache
    const templates = this.getTemplatesFromCache();

    templates.push({
      ...data,
      tab: EntityTypes.TEMPLATE,
      created: Math.round(Date.now() / 1000),
      modified: Math.round(Date.now() / 1000),
      objectID: templateId,
    });

    this.cacheTemplates({ userId, templates });

    // Push update to instances of the notebook
    this.pushTemplatesToInstances({ userId });

    // Wait a millisecond before resolving, so we are sure that the
    // data-update has propagated to any open client
    await asyncTimeout(1);

    // Resolve promise
    return templateId;
  }

  /** Updates a template with the specified data */
  public static async updateTemplate(data: Template): Promise<string> {
    // Make sure that the list of all templates have been cached before
    // updating this template
    if (!this.getTemplatesFromCache()) {
      await this.loadTemplates();
    }

    const userId = window.gProxy.getToken().UserId ?? "";

    // Update note in the remote API
    await updateEntity(data);

    // Now update the template in the internal cache
    const templates = this.getTemplatesFromCache().map((template) => {
      if (template.objectID !== data.objectID) {
        return template;
      } else {
        return {
          ...template,
          ...data,
          modified: Math.round(Date.now() / 1000),
        };
      }
    });

    this.cacheTemplates({ userId, templates });

    // Push update to instances of the notebook
    this.pushTemplatesToInstances({ userId });

    // Wait a millisecond before resolving, so we are sure that the
    // data-update has propagated to any open client
    await asyncTimeout(1);

    // Resolve promise
    return data.objectID ?? "";
  }

  /** Deletes the given template. */
  public static async deleteTemplate(data: {
    objectID: string;
  }): Promise<null> {
    // Make sure that the list of all templates have been cached before
    // updating this template
    if (!this.getTemplatesFromCache()) {
      await this.loadTemplates();
    }

    const userId = window.gProxy.getToken().UserId ?? "";

    // Update template in the remote API
    await deleteEntity(EntityTypes.TEMPLATE, data.objectID);

    // Now remove the template from the internal cache
    const templates = this.getTemplatesFromCache().filter((template) => {
      return template.objectID !== data.objectID;
    });

    this.cacheTemplates({ userId, templates });

    // Push update to instances of the notebook
    this.pushTemplatesToInstances({ userId });

    // Wait a millisecond before resolving, so we are sure that the
    // data-update has propagated to any open client
    await asyncTimeout(1);

    // Resolve promise
    return null;
  }

  /**
   * Internal helper-method that's triggered whenever a note has been either
   * created, updated or deleted using the built-in methods in this class,
   * it makes sure to push the updates to any currently open notebook clients.
   */
  private static pushNotesToInstances(params: {
    userId: string;
    productId: string;
  }): void {
    const { userId, productId } = params;

    Object.keys(this.instances).forEach((instanceId) => {
      // If this instance doesn't match the given userId or productId,
      // then skip it...
      if (
        !this.instances[instanceId] ||
        this.instances[instanceId].productId !== productId ||
        this.instances[instanceId].userId !== userId
      ) {
        return;
      }

      // ... Otherwise post the given notes to it!
      this.instances[instanceId].postMessage(
        "update:notes",
        JSON.stringify(this.getNotesFromCache({ userId, productId }))
      );
    });
  }

  /**
   * Internal helper-method that's triggered whenever a template has been
   * either created, updated or deleted using the built-in methods in this
   * class, it makes sure to push the updates to any currently open notebook
   * clients.
   */
  private static pushTemplatesToInstances(params: { userId: string }): void {
    const { userId } = params;

    Object.keys(this.instances).forEach((instanceId) => {
      // If this instance doesn't match the given userId or productId,
      // then skip it...
      if (
        !this.instances[instanceId] ||
        this.instances[instanceId].userId !== userId
      ) {
        return;
      }

      // ... Otherwise post the given notes to it!
      this.instances[instanceId].postMessage(
        "update:templates",
        JSON.stringify(this.getTemplatesFromCache({ userId }))
      );
    });
  }

  /**
   * Internal helper-method that returns a premade iframe instance, ready to
   * be mounted into the DOM.
   */
  private static createFrame(): HTMLIFrameElement {
    // Create a new iframe
    const iframe = document.createElement("iframe");
    iframe.name = "notebook";

    iframe.frameBorder = "0";
    iframe.setAttribute("allowTransparency", "true");

    // Hide the frame until mounted
    iframe.width = "0";
    iframe.height = "0";

    iframe.style.position = "absolute";
    iframe.style.top = "-100px";
    iframe.style.left = "-100px";
    iframe.style.visibility = "hidden";

    // Init frame
    iframe.src = "about:blank";

    // Return the premade frame
    return iframe;
  }

  /** Helper-method that opens a new window at 1024x768px. */
  private static openWindow(url: string): void {
    // Calculate the current size and position of the window
    const WIN_X = window.screenX || window.screenLeft || 0;
    const WIN_Y = window.screenY || window.screenTop || 0;
    const WIN_W = window.innerWidth;
    const WIN_H = window.innerHeight;

    // Open the popup-window
    const popup = window.open(
      url,
      "",
      `width=1024,` +
        `height=768,` +
        `left=${Math.round(WIN_X + (WIN_W - 1024) / 2)},` +
        `top=${Math.round(WIN_Y + (WIN_H - 768) / 2)}`
    );

    if (popup) {
      popup.focus();
    }
  }

  /**
   * Helper-method that executes the given callback once the notebook has
   * been loaded and rendered.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public wait = (cb: (...args: any[]) => void): void => {
    this[loadPromise].then(cb);
  };

  /**
   * Helper-method that updates the current display-settings for the notebook.
   */
  public updateSettings = (
    params: { className?: string; noteId?: string; templateId?: string } = {}
  ): void => {
    const { className, noteId, templateId } = params;

    // Update className
    if (className) {
      this[frame].className = className;
    }

    // Open a specific note or template?
    if (noteId) {
      this.openNote(noteId);
    } else if (templateId) {
      this.openTemplate(templateId);
    }
  };

  /**
   * Helper-method that navigates to the specified note inside the currently
   * opened notebook-client.
   */
  public openNote = (noteId: string): void => {
    if (this[frame].contentWindow) {
      this.postMessage("open", `${EntityTypes.NOTE}/${noteId}/`);
    }
  };

  /**
   * Helper-method that navigates to the specified template inside the
   * currently opened notebook-client.
   */
  public openTemplate = (templateId: string): void => {
    if (this[frame].contentWindow) {
      this.postMessage("open", `${EntityTypes.TEMPLATE}/${templateId}/`);
    }
  };

  /**
   * Wrapper around the "setContext"-method, targetting a specific active
   * instance of the notebook-client.
   */
  public setContext = (context: Context): void => {
    this.wait(() => {
      this.postMessage("setContext", JSON.stringify(context));
    });
  };

  /**
   * Mounts the notebook onto the given DOM-node, making sure that it will
   * fill out the given node.
   */
  public mountTo = (node: HTMLElement): void => {
    const windowNode = window.Node;

    if (windowNode && !(node instanceof Node)) {
      throw new TypeError("The mount-node must be given as a DOM node.");
    }

    if (!("innerHTML" in node) || node.innerHTML) {
      throw new TypeError(
        "The mount-node must have an empty innerHTML property."
      );
    }

    if (!this[throbber]) {
      this[throbber] = document.createElement("div");
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this[throbber]!.className = "g-notebook-tmp-throbber-wrapper";

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this[throbber]!.innerHTML =
        `<div class="g-notebook-tmp-throbber">` +
        `<div class="g-notebook-tmp-throbber-spinner">` +
        `<div class="g-notebook-tmp-throbber-spinner-indicator"></div>` +
        `</div>` +
        `</div>`;
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    node.appendChild(this[throbber]!);
    node.appendChild(this[frame]);

    this[frame].width = "100%";
    this[frame].height = "100%";
    this[frame].style.visibility = "visible";
    this[frame].style.position = "relative";
    this[frame].style.top = "";
    this[frame].style.left = "";
    this[frame].style.zIndex = "1";

    const iframe = this[frame];

    if (iframe.parentElement) {
      iframe.parentElement.style.background = "#f5f2e0";
    }

    // Determine if we should add drag'n'drop functionality?
    if (!IsMobile.any()) {
      this.bindDragDrop();
    }
  };

  /**
   * Helper-method that returns the current width of the notebook in pixels.
   */
  public getCurrentWidth = (): number => {
    if (this[isExpanded] !== true) {
      return this[frame].offsetWidth;
    } else {
      return this[currentWidth];
    }
  };

  /**
   * Ask the notebook to unmount itself, making sure to save any changes made
   * to the current note and then removing it completely from the interface.
   */
  public unmount = async (): Promise<void> => {
    this[isCurrentlyUnmounting] = true;

    const tstamp = Date.now();

    // If the notebook has not been loaded yet, then there is no reason to
    // attempt saving changes
    if (this[isLoaded]) {
      // Ask the notebook to save any currently associated changes
      try {
        await this.saveChanges();
      } catch (err) {
        throw new gNotebook.ERRORS.ChangesNotSavedError();
      }
    }

    // Wait at least 400ms before completely unmounting / destroying the
    // client - so the parent window has time to complete any transitions
    // made...
    await asyncTimeout(Math.max(0, 400 - (Date.now() - tstamp)));

    // Now destroy the instance completely
    if (this[isCurrentlyUnmounting] === true) {
      this.destroy();

      // Unmount was successful - resolve promise...
      return;
    }

    // Unmount was aborted...
    throw new gNotebook.ERRORS.UnmountAbortedError();
  };

  /**
   * Helper-mehtod that makes the notebook save any currently made changes.
   */
  public async saveChanges(): Promise<void> {
    const saveId = `${Date.now()}_${Math.round(Math.random() * 9999 + 1)}`;

    const promise = new Promise<void>((resolve, reject) => {
      this.once(`save:done:${saveId}`, () => {
        resolve();
      });

      this.once(`save:fail:${saveId}`, () => {
        reject(new Error("Changes couldn't be saved."));
      });
    });

    promise.then(
      () => {
        this.off(`save:fail:${saveId}`);
      },
      () => {
        this.off(`save:done:${saveId}`);
      }
    );

    this.postMessage("save", saveId);

    return promise;
  }

  /**
   * Determine if the notebook is currently pending an unmount from the DOM
   * (this is useful if the user requests reopening the notebook before the
   * old client is completely removed from the DOM - allowing us to reuse the
   * existing implementation).
   */
  public isUnmounting = (): boolean => {
    return this[isCurrentlyUnmounting] === true;
  };

  /**
   * Call out to this method, if you want to abort unmounting the notebook (
   * for instance if the user would like to re-open the notebook).
   */
  public abortUnmount = (): void => {
    if (this[destroyed]) {
      throw new gNotebook.ERRORS.NotebookUnmountedError();
    }

    this[isCurrentlyUnmounting] = false;
  };

  // Proxy "fire", "on", "once" and "off" to the static class
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public fire = (...args: any[]): void => {
    // @ts-expect-error Poor implementation
    return gNotebook.fire.apply(this, args);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public on(...args: any[]): void {
    // @ts-expect-error Poor implementation
    return gNotebook.on.apply(this, args);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public once(...args: any[]): void {
    // @ts-expect-error Poor implementation
    return gNotebook.once.apply(this, args);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public off(...args: any[]): void {
    // @ts-expect-error Poor implementation
    return gNotebook.off.apply(this, args);
  }

  /**
   * Destroy the notebook client completely, allowing memory to be cleared
   * when it's no longer needed. Generally speaking you shouldn't use this
   * method directly - instead call unmount, which handles all logic
   * required to save the note.
   */
  protected destroy = (): void => {
    // Resolve load promise so no functions are hanging, causing a memory
    // leak...
    this[loadPromise].resolve();

    // Unmount the note
    if (this[frame]) {
      this[frame]?.parentElement?.style.setProperty("background", null);
      this[frame].remove();
    }

    if (this[dragBar]) {
      this[dragBar].remove();
    }

    if (this[throbber]) {
      this[throbber]?.remove();
    }

    // @ts-expect-error Poor use of symbols
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete this[frame];

    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete this[dragBar];
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete this[throbber];

    // @ts-expect-error Poor use of symbols
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete this[events];
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete gNotebook.instances[this.instanceId];

    this[destroyed] = true;
    this[isCurrentlyUnmounting] = false;
  };

  /**
   * This method is triggered just before the notebook is expanded, and makes
   * sure to update it's state.
   */
  private onExpand = (): void => {
    this[currentWidth] = this.getCurrentWidth();
    this[isExpanded] = true;

    // Hide background while transitioning

    const iframe = this[frame];

    // Hide background while transitioning
    if (iframe.parentElement) {
      iframe.parentElement.style.background = "none";
    }

    setTimeout(() => {
      if (iframe.parentElement) {
        iframe.parentElement.style.background = "#f5f2e0";
      }
    }, 650);
  };

  /** This method is triggered just before the notebook is collapsed */
  private onBeforeCollapse = (): void => {
    const iframe = this[frame];

    // Hide background while transitioning
    if (iframe.parentElement) {
      iframe.parentElement.style.background = "none";
    }

    setTimeout(() => {
      if (iframe.parentElement) {
        iframe.parentElement.style.background = "#f5f2e0";
      }
    }, 650);
  };

  /**
   * This method is triggered just after the notebook is collapsed, and makes
   * sure to update it's state.
   */
  private onCollapse = (): void => {
    this[isExpanded] = false;
  };

  /**
   * Internal helper-method that will bind drag'n'drop functionality to the
   * notebook, allowing the user to expand/collapse the layer (please note
   * that this will not work unless the third party application has
   * explicitly implemented this feature).
   */
  private bindDragDrop = (): void => {
    // These shared variables are used to keep track of current drag'n'drop
    // sessions...
    let startX: number;
    let currentX: number;
    let startW: number;
    let overlay: HTMLElement;
    let animationFrame: number;

    // Helper-method that's triggered during the browsers animationFrame-
    // event, making sure we only update the UI when the browser is primed
    // for doing so...
    const onAnimationFrame = () => {
      // Calculate boundaries
      const boundaryMin = 375;
      const boundaryMax = Math.min(
        767,
        Math.max(window.innerWidth - 390, Math.floor(window.innerWidth / 2))
      );

      // Calculate desired width of the notebook
      const width = Math.max(
        boundaryMin,
        Math.min(boundaryMax, startW + (startX - currentX))
      );

      // Trigger the 'drag'-event, and hope that the thirdpart client has
      // correctly implemented this feature...
      /**
       * This event is fired as the user drags the size of the notebook-
       * client. You must bind an event-listener to this event and
       * manually adjust the size of the notebook, in order for this
       * feature to work as expected.
       *
       * The desired width of the notebook is passed as a parameter.
       */
      this.fire("drag", width);

      // Request animation-frame again
      animationFrame = requestAnimationFrame(onAnimationFrame);
    };

    /**
     * Helper-method that's triggered as the user moves the cursor
     * around, update current mouse coordinates
     */
    function onMouseMove(evt: MouseEvent): void {
      currentX = evt.pageX || evt.clientX;
      evt.preventDefault();
    }

    // Helper-method that's triggered when the user releases his mouse
    // - should end the drag'n'drop session
    const onMouseUp = (evt: MouseEvent) => {
      // Abort animation-frames
      cancelAnimationFrame(animationFrame);

      // Remove the overlay again
      this[frame].parentNode?.removeChild(overlay);

      // Unbind event-listeners
      document.removeEventListener("mousemove", onMouseMove, false);
      document.removeEventListener("mouseup", onMouseUp, false);

      /**
       * This event is fired when the user ends a drag'n'drop session,
       * and can be used to reset the UI.
       */
      this.fire("drag:end");

      // Reset displayed cursor
      document.body.style.cursor = "";

      // Prevent default event-handling
      evt.preventDefault();
    };

    // Helper-method that's triggered when the user clicks his mouse down -
    // should begin the drag'n'drop handling
    const onMouseDown = (evt: MouseEvent) => {
      // Detect starting coordinates
      startW = this[frame].offsetWidth;
      startX = evt.pageX || evt.clientX;
      currentX = startX;

      // Bind event-listeners to handle the drag'n'drop session
      document.addEventListener("mousemove", onMouseMove, false);
      document.addEventListener("mouseup", onMouseUp, false);

      // Request animation-frame to update UI while dragging
      animationFrame = requestAnimationFrame(onAnimationFrame);

      // Inject an overlay on top of the iframe, making sure that the
      // frame will not intercept mouse-events
      overlay = document.createElement("div");
      overlay.style.position = "absolute";
      overlay.style.top = `${this[frame].offsetTop}px`;
      overlay.style.left = `${this[frame].offsetLeft}px`;
      overlay.style.bottom = "0";
      overlay.style.right = "0";
      overlay.style.background = "transparent";
      overlay.style.zIndex = "10";

      this[frame].parentNode?.appendChild(overlay);

      // Update displayed cursor
      document.body.style.cursor = "ew-resize";

      /**
       * This event is fired when the user begins drag'n'drop of the
       * notebook (ie. resizing it manually).
       */
      this.fire("drag:begin");

      // Prevent default event-handling
      evt.preventDefault();
    };

    // Create a drag'n'drop-bar, that will allow the user to start
    // expanding the iframe
    this[dragBar] = document.createElement("div");

    this[dragBar].style.position = "absolute";
    this[dragBar].style.top = `${this[frame].offsetTop}px`;
    this[dragBar].style.left = `${this[frame].offsetLeft - 5}px`;
    this[dragBar].style.bottom = "0";
    this[dragBar].style.zIndex = "11";
    this[dragBar].style.width = "10px";
    this[dragBar].style.background = "transparent";
    this[dragBar].style.cursor = "ew-resize";

    this[frame].parentNode?.appendChild(this[dragBar]);

    // Listen to the mouseDown-event (this is the only event we need to
    // bind initially)
    this[dragBar].addEventListener("mousedown", onMouseDown, false);
  };

  /**
   * Internal helper-method that posts a message to the embedded notebook-
   * client, allowing us to handle cross-domain communication.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private postMessage = (event: string, data: any): void => {
    if (
      !window.postMessage ||
      !this[frame] ||
      !this[frame].contentWindow ||
      !this[frame].contentWindow?.postMessage
    ) {
      return;
    }

    let message = "";

    message += `gNotebook:${event}`;
    message += `/${this.instanceId}/`;
    message += data;

    this[frame].contentWindow?.postMessage(message, "*");
  };
}

/**
 * Listen to the onload-event and inject throbber stylesheet into the head
 */
function addCustomStylesheet(): void {
  const stylesheet = document.createElement("style");
  const css =
    ".g-notebook-tmp-throbber-wrapper {" +
    "position: absolute;" +
    "top: 0;" +
    "left: 0;" +
    "bottom: 0;" +
    "right: 0;" +
    "background: #f5f2e0;" +
    "}" +
    ".g-notebook-tmp-throbber {" +
    "position: absolute;" +
    "top: 50%;" +
    "left: 50%;" +
    "margin-left: -32px;" +
    "margin-top: -32px;" +
    "width: 60px;" +
    "height: 60px;" +
    "border: 2px solid #c6c4b6;" +
    "border-radius: 50%;" +
    "-webkit-border-radius: 50%;" +
    "-moz-border-radius: 50%;" +
    "-ms-border-radius: 50%;" +
    "-o-border-radius: 50%;" +
    "}" +
    ".g-notebook-tmp-throbber-spinner {" +
    "position: absolute;" +
    "top: -2px;" +
    "left: -2px;" +
    "bottom: -2px;" +
    "right: -2px;" +
    "animation: g-notebook-tmp-throbber-spinner linear 800ms infinite;" +
    "-webkit-animation: g-notebook-tmp-throbber-spinner linear 800ms infinite;" +
    "-moz-animation: g-notebook-tmp-throbber-spinner linear 800ms infinite;" +
    "-ms-animation: g-notebook-tmp-throbber-spinner linear 800ms infinite;" +
    "-o-animation: g-notebook-tmp-throbber-spinner linear 800ms infinite;" +
    "}" +
    ".g-notebook-tmp-throbber-spinner-indicator {" +
    "position: absolute;" +
    "top: 0;" +
    "left: 0;" +
    "width: 20px;" +
    "height: 20px;" +
    "overflow: hidden;" +
    "}" +
    ".g-notebook-tmp-throbber-spinner-indicator:before {" +
    "position: absolute;" +
    "top: 0;" +
    "left: 0;" +
    "width: 60px;" +
    "height: 60px;" +
    "border: 2px solid rgba(245, 242, 224, 0.8);" +
    "border-radius: 50%;" +
    "-webkit-border-radius: 50%;" +
    "-moz-border-radius: 50%;" +
    "-ms-border-radius: 50%;" +
    "-o-border-radius: 50%;" +
    'content: " ";' +
    "}" +
    "@-webkit-keyframes g-notebook-tmp-throbber-spinner {" +
    "from {" +
    "transform: rotate(0deg);" +
    "-webkit-transform: rotate(0deg);" +
    "-moz-transform: rotate(0deg);" +
    "-ms-transform: rotate(0deg);" +
    "-o-transform: rotate(0deg);" +
    "}" +
    "to {" +
    "transform: rotate(360deg);" +
    "-webkit-transform: rotate(360deg);" +
    "-moz-transform: rotate(360deg);" +
    "-ms-transform: rotate(360deg);" +
    "-o-transform: rotate(360deg);" +
    "}" +
    "}" +
    "@keyframes g-notebook-tmp-throbber-spinner {" +
    "from {" +
    "transform: rotate(0deg);" +
    "-webkit-transform: rotate(0deg);" +
    "-moz-transform: rotate(0deg);" +
    "-ms-transform: rotate(0deg);" +
    "-o-transform: rotate(0deg);" +
    "}" +
    "to {" +
    "transform: rotate(360deg);" +
    "-webkit-transform: rotate(360deg);" +
    "-moz-transform: rotate(360deg);" +
    "-ms-transform: rotate(360deg);" +
    "-o-transform: rotate(360deg);" +
    "}" +
    "}";

  stylesheet.type = "text/css";

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const styleSheet = (stylesheet as any).styleSheet;

  if (styleSheet) {
    styleSheet.cssText = css;
  } else {
    stylesheet.appendChild(document.createTextNode(css));
  }

  document.getElementsByTagName("head")[0].appendChild(stylesheet);
}

if (document.getElementsByTagName("head")[0]) {
  addCustomStylesheet();
} else {
  window.addEventListener("load", addCustomStylesheet);
}

/**
 * Extend the window object with information about the gNotebook
 */
declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    gNotebook: typeof gNotebook;
  }
}

// Expose notebook on window object
window.gNotebook = gNotebook;

// Create placeholders for different data
window.gNotebook[events] = {};
window.gNotebook[cache] = {};

window.gNotebook.ERRORS = {};

// Create custom errors
Object.keys(window.gNotebook.ERROR_MESSAGES).forEach((key) => {
  const message = window.gNotebook.ERROR_MESSAGES[key];

  window.gNotebook.ERRORS[key] = class NotebookError extends CustomError {
    /** Specifies the name of the error */
    public name: string = key;

    /** Contains the message of the error */
    public message: string = message;
  };
});

// Listen to messages from mounted notebooks
window.addEventListener("message", window.gNotebook.onMessage, false);
