import type { Entity } from "../../../models/Entity";
import type { APIEntity } from "../typings";

export function convertEntityToRequest(
  entity: Entity
): Omit<APIEntity, "created" | "modified"> {
  return {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    objectID: entity.objectID!,
    userID: entity.userID ?? window.gUserId,
    userInstitutionID: entity.userInstitutionId ?? window.gUserInstitutionId,
    productID: entity.productId ?? window.gProductId,
    title: entity.title,
    content: entity.content,
    lockedContent: entity.lockedContent,
    context: entity.context,
    shared: entity.shared,
    predefinedNoteID: entity.predefinedNoteID,
    type: entity.type,
    tags: entity.tags,
  };
}
