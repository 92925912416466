/**
 * Simple helper-utility that detects whether the current device is mobile-
 * based or not.
 */
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class IsMobile {
  /**
   * Detects if the current device is an Android phone/tablet.
   */
  public static android(): boolean {
    return !!navigator.userAgent.match(/Android/i);
  }

  /**
   * Detects if the current device is an Blackberry phone/tablet.
   */
  public static blackberry(): boolean {
    return !!navigator.userAgent.match(/BlackBerry/i);
  }

  /**
   * Detects if the current device is an iOS phone/tablet.
   */
  public static iOS(): boolean {
    return !!navigator.userAgent.match(/iPhone|iPad|iPod/i);
  }

  /**
   * Detects if the current device is an Opera phone/tablet.
   */
  public static opera(): boolean {
    return !!navigator.userAgent.match(/Opera Mini/i);
  }

  /**
   * Detects if the current device is an Windows phone/tablet.
   */
  public static windows(): boolean {
    return !!navigator.userAgent.match(/IEMobile/i);
  }

  /**
   * Detects if the current device falls under any of defined mobile
   * categories.
   */
  public static any(): boolean {
    return (
      IsMobile.android() ||
      IsMobile.blackberry() ||
      IsMobile.iOS() ||
      IsMobile.opera() ||
      IsMobile.windows()
    );
  }
}
