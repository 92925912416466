import type {
  Entity,
  EntityTypes,
  Note,
  Template,
} from "../../../models/Entity";
import type { APIEntity } from "../typings";

export function convertResponseToEntity<T extends EntityTypes>(
  apiEntity: APIEntity,
  type: T
): T extends EntityTypes.NOTE
  ? Note
  : T extends EntityTypes.TEMPLATE
  ? Template
  : Entity {
  // @ts-expect-error ReturnType cannot be inferred properly
  return {
    ...apiEntity,
    objectKey: apiEntity.objectID,
    tab: type,
    tags: apiEntity.tags,
  };
}
