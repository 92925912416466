/**
 * This error-class only serves as an interface that can be extended, which
 * can be extended to create custom exceptions easily.
 */
export class CustomError extends Error {
  /**
   * Define the name of the custom error by overriding this property.
   */
  public name = "CustomError";

  /**
   * Define the default message of the custom error by overriding this
   * property.
   */
  public message = "Custom error event";

  constructor(message?: string) {
    super(message);

    this.message = message || "Default Message";
    this.stack = new Error().stack;
  }
}
