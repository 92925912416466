/**
 * The native string-object, which we extend to make helper-methods easily
 * available.
 */
import diacritics from "diacritics";

const HTML_DECODER = document.createElement("div");

/** Expose new string methods globally */
declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface String {
    removeDiacritics(): string;
    normalizeWhitespace(): string;
    removeRepeatedChars(): string;
    stripHTML(): string;
    naturallySorted(): string;
    searchable(): string;
  }
}

/**
 * Simple proxy to the diacritics.remove-method.
 */
String.prototype.removeDiacritics = function removeDiacritics(): string {
  // @ts-expect-error Bad practice to extend string
  return diacritics.remove(this);
};

/**
 * Convert all whitespace to regular spaces.
 */
String.prototype.normalizeWhitespace = function normalizeWhitespace(): string {
  return this.replace(/\s+/g, " ");
};

/**
 * Helper-method that uses a regular expression to remove repeated characters
 * in a string. Useful when searching through data.
 */
String.prototype.removeRepeatedChars = function removeRepeatedChars(): string {
  return this.replace(/(.)\1+/g, "$1");
};

/**
 * Helper-method that strips any HTML-formatting from the string.
 */
String.prototype.stripHTML = function stripHTML(): string {
  HTML_DECODER.innerHTML = this.toString()
    .replace(/<br *\/?>/gi, "\n")
    .replace(/<\/(div|p)>/gi, "\n");

  return HTML_DECODER.innerText;
};

/**
 * Helper-method that makes a string searchable by removing diacritic marks,
 * converting it to lowercase, normalizing whitespace, removing repeated
 * characters and only allowing word-characters.
 */
String.prototype.searchable = function searchable(): string {
  return this.stripHTML()
    .removeDiacritics()
    .toLowerCase()
    .normalizeWhitespace()
    .removeRepeatedChars()
    .replace(/^ +/, "")
    .replace(/ +$/, "");
};

/**
 * Helper-method that makes a version of the string that will be sorted
 * naturally by padding all numbers up to 12 digits.
 */
String.prototype.naturallySorted = function naturallySorted(): string {
  return this.replace(/[0-9]+/g, (num: string): string => {
    let sNum = String(num);

    while (sNum.length < 12) {
      sNum = `0${sNum}`;
    }

    return sNum;
  });
};
