import axios from "axios";

import { EntityTypes } from "../../models/Entity";

export async function deleteEntity(
  type: EntityTypes,
  objectID: string
): Promise<void> {
  const url =
    type === EntityTypes.NOTE
      ? `${process.env.USERDATA_API}/api/Notes/${encodeURIComponent(objectID)}`
      : `${process.env.USERDATA_API}/api/NoteTemplates/${encodeURIComponent(
          objectID
        )}`;

  await axios.request({
    url,
    method: "DELETE",
  });
}
