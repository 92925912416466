import "../utils/string";

import type { Context } from "./Context";

/** Specify all types of notes that occur within the notebook */
export enum EntityTypes {
  NOTE = "note",
  TEMPLATE = "template",
}

/** Full specificaiton of all available properties on a note */
export type BaseEntity = {
  /** The ID of the note. */
  objectID?: string;

  /**
   * In order to avoid rerenders when creating new notes locally we use this
   * field as the key for rendering within React.
   */
  objectKey: string;

  /** A string with one of the values available in the NoteTypes enumeration. */
  tab: EntityTypes;

  /** A string that indicates the type of the note. */
  type?: string;

  /** The ID of the user who owns the note. */
  userID: string;

  /** An ID of the predefined note, on which this note is based. */
  predefinedNoteID?: string;

  /** Unix epoch timestamp of the creation. */
  created: number;

  /** Unix epoch timestamp of last modification. */
  modified: number;

  /** The title of the note. */
  title: string;

  /** The locked HTML-contents of the note. */
  lockedContent?: string;

  /** The HTML-contents of the note */
  content: string;

  /** A reference to the product, which the note is associated with. */
  productId?: string;

  userInstitutionId?: string;

  /** A reference to the context of the note. */
  context?: Context;

  /**
   * If present, this value defines whether or not the note has been shared
   * with other users.
   */
  shared?: boolean;

  /** If present, this indicates a reference between a note and a dialogue. */
  dialogueID?: boolean;

  /**
   * Used by the frontend-client, will be set to true once the note has been
   * deleted.
   */
  isDeleted?: boolean;

  /** Contains a set of tags associated with the current node */
  tags?: string[];
};

export type Note = {
  tab: EntityTypes.NOTE;
} & BaseEntity;

export type Template = {
  tab: EntityTypes.TEMPLATE;
} & BaseEntity;

export type Entity = Note | Template;
