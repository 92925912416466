import axios from "axios";

import type { Entity } from "../../models/Entity";
import { EntityTypes } from "../../models/Entity";

import { convertEntityToRequest } from "./utils/convertEntityToRequest";

export async function updateEntity(entity: Entity): Promise<void> {
  const url =
    entity.tab === EntityTypes.NOTE
      ? `${process.env.USERDATA_API}/api/Notes/${encodeURIComponent(
          entity.objectID ?? ""
        )}`
      : `${process.env.USERDATA_API}/api/NoteTemplates/${encodeURIComponent(
          entity.objectID ?? ""
        )}`;

  await axios.request<undefined>({
    url,
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(convertEntityToRequest(entity)),
  });
}
